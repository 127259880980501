import { useTranslation } from "react-i18next"
import { useEffect } from "react"

export function PasswordValidator({ password, onValidationChange }) {
    const { t } = useTranslation()
    
    const lengthRegex = /^.{8,}$/
    const capitalLetterRegex = /[A-Z]/
    const numberRegex = /\d/
    const specialCharRegex = /[!@#$%^&*]/

    const validations = [
        { isValid: lengthRegex.test(password), message: t("atLeast8Characters") },
        { isValid: capitalLetterRegex.test(password), message: t("atLeastOneUppercaseLetter") },
        { isValid: numberRegex.test(password), message: t("atLeastOneNumber") },
        { isValid: specialCharRegex.test(password), message: t("atLeastOneSpecialCharacter") }
    ];

    const isValid = validations.every((validation) => validation.isValid)

    useEffect(() => {
        onValidationChange(isValid)
    }, [isValid, onValidationChange])


    return (
        <div className="mt-2 space-y-1">
            {validations.map((validation, index) => (
                <div key={index} className="flex items-center">
                    <span className={`w-4 h-4 rounded-full mr-2 ${validation.isValid ? 'bg-green-500' : 'bg-red-500'}`}></span>
                    <span className={`text-sm ${validation.isValid ? 'text-green-500' : 'text-red-500'}`}>
                        {validation.message}
                    </span>
                </div>
            ))}
        </div>
    );
}
