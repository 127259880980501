import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

export function LineChart({ data }) {
    const chartRef = useRef(null);

    useEffect(() => {
        const ctx = chartRef.current.getContext('2d');

        const lineChart = new Chart(ctx, {
            type: 'line',
            data: {
                labels: data,
                datasets: [
                    {
                        data: data,
                        fill: true,
                        borderColor: 'rgba(87, 151, 15, 1)',
                        backgroundColor: 'rgba(87, 151, 15, 0.2)',
                    },
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                clip: false,
                scales: {
                    y: {
                        min: 20,
                        max: 100,
                        ticks: {
                            crossAlign: "far",
                            stepSize: 20,
                            callback: (value) => value + "%",

                        },
                        grid: {
                            drawTicks: false,
                            display: true,
                        },
                        border: {
                            display: false,
                        }
                    },
                    x: {
                        ticks: {
                            display: false,
                        },
                        grid: {
                            display: false,
                        },
                    }
                },
                plugins: {
                    legend: false,
                },
                elements: {
                    point: {
                        radius: 0,
                    }
                }
            }
        });

        return () => {
            lineChart.destroy(); // Cleanup the chart on component unmount
        };
    }, [data]);

    return (
        <div className="w-full h-56 overflow-hidden">
            <canvas ref={chartRef} />
        </div>
    );
};