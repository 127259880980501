import { MdOutlineDone, MdErrorOutline } from "react-icons/md"
import { useEffect } from "react"
import { useDispatch } from "react-redux"

export function Toast({ status, message, hide, reset, duration = 3000 }) {
    const dispatch = useDispatch()

    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(reset())
            hide()
        }, duration)

        return () => clearTimeout(timer)
    }, [dispatch, reset, hide, duration])

    return (
        <div className="fixed top-5 right-5 z-10">
            <div className={`shadow w-full text-white ${status === "success" ? "bg-green-800" : "bg-red-800"} rounded-lg`} role="alert">
                <div className="flex flex-row items-center space-x-2 p-4">
                    {status === "success" ? (
                        <div className="inline-flex items-center justify-center flex-shrink-0 w-6 h-6 rounded-lg bg-green-800 text-green-200">
                            <MdOutlineDone className="w-6 h-6" />
                        </div>
                    ) : (
                        <div className="inline-flex items-center justify-center flex-shrink-0 w-6 h-6 rounded-lg bg-red-800 text-red-200">
                            <MdErrorOutline className="w-6 h-6" />
                        </div>
                    )}
                    <p className="text-sm font-normal flex-grow">{message}</p>
                </div>
            </div>
        </div>
    )
}
