import axios from "axios"

const agent = axios.create({
    baseURL: "https://h2genio.com.br:4433/api",
    headers: { "accept": "*/*", "Content-Type": "application/json", "Access-Control-Allow-Origin": "*" },
})

agent.interceptors.request.use(config => {
    const token = sessionStorage.getItem("accessToken")
    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }
    return config
})

/*
agent.interceptors.response.use(
    response => response,
    error => {
      if (error.response.status === 401) {
        console.log("Toma um 401")
      }
      
      return Promise.reject(error);
    }
)
*/

export default agent