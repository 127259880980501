import { twMerge } from 'tailwind-merge'

export function InputContent({ icon, id, type, placeholder, disabled = false, className, ...props }) {
    return (
        <div className='relative'>
            {icon}
            <input
                id={id}
                name={id}
                type={type}
                placeholder={placeholder}
                disabled={disabled}
                className={twMerge(
                `bg-white p-2 w-full placeholder-h2v-input text-sm lg:text-base text-h2v-input block box-border border-2 border-gray-300 focus:outline-none focus:border-h2v-focus focus:placeholder-gray-500 rounded-lg ${icon ? 'pl-10' : ''}`,
                    className
                )}
                {...props}
            />
        </div>
    )
}