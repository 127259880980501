import { useRouteError } from "react-router-dom"

export function ErrorPage() {
    const error = useRouteError()
    console.error(error)

    return (
        <div id="error-page" className="h-screen flex items-center justify-center">
            <div className="grid justify-items-center space-y-4">
                <h1>Oops!</h1>
                <p>Desculpa, um erro acabou de acontecer</p>
                <p>
                    <i>{error.statusText || error.message}</i>
                </p>
            </div>
        </div>
    )
}