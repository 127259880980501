import { ModalBottom } from "./ModalBottom"
import { ModalContent } from "./ModalContent"
import { ModalTitle } from "./ModalTitle"
import { ModalRoot } from "./ModalRoot"
import { ModalText } from "./ModalText"
import { ModalForm } from "./ModalForm"

export const Modal = {
    Root: ModalRoot,
    Content: ModalContent,
    Title: ModalTitle,
    Text: ModalText,
    Form: ModalForm,
    Bottom: ModalBottom,
}