import React from "react"
import { useTranslation } from "react-i18next"
import AmericanFlag from "../../assets/american-flag.svg"
import BrazilFlag from "../../assets/brazil-flag.svg"


import { Flag } from './Flag'

export function I18n() {
  const { i18n } = useTranslation()

  function handleChangeLanguage(language) {

    i18n.changeLanguage(language)
  }

  const selectedLanguage = i18n.language

  return (
    <div className="flex space-x-4 w-full items-center">
      <Flag
        image={BrazilFlag}
        isSelected={selectedLanguage === 'pt-BR'}
        onClick={() => handleChangeLanguage('pt-BR')}
      />
      <Flag
        image={AmericanFlag}
        isSelected={selectedLanguage === 'en-US'}
        onClick={() => handleChangeLanguage('en-US')}
      />
    </div>
  )
}