import agent from "./api"

const USER = "/user"

export async function createUser(name, email, phone, role) {
    return await agent.post(USER, { 
        name: name, 
        email: email, 
        phone: phone, 
        role: role,
    })
}

export async function getUsers() {
    return await agent.get(USER)
}

export async function getUserByID(id) {
    return await agent.get(USER + `/${id}`)
}

export async function updateUserByID(id, name, phone, role) {
    return await agent.patch(USER + `/${id}`, {
        name: name, 
        phone: phone, 
        role: role,
    })
}

export async function updateEmailByID(id, email) {
    return await agent.patch(USER + `/${id}/update-email`, {email: email})
}

export async function archiveUserByID(id, status) {
    return await agent.patch(USER + `/${id}/archive-user`, {status: status})
}

