import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';

export function NavItem({ path, currentPath, onClick, Icon, label }) {
    const [hovered, setIsHovered] = useState(false);

    useEffect(() => {
    }, [hovered])

    const { openState } = useSelector((state) => state.sideBar);

    return (
        <li
            className="lg:w-full flex justify-center"
            onMouseEnter={() => {
                if (path !== currentPath) {
                    setIsHovered(true);
                }
            }}
            onMouseLeave={() => {
                if (path !== currentPath) {
                    setIsHovered(false);
                }
            }}
        >
            <div
                className={`flex items-center p-2 ${
                    openState
                        ? "rounded-md"
                        : "w-full lg:rounded-l-md lg:rounded-r-none"
                } hover:bg-white hover:text-h2v-green cursor-pointer ${
                    path === currentPath && !hovered ? "bg-white text-h2v-green" : "text-white"
                }`}
                onClick={onClick}
            >
                <Icon
                    className={`w-8 h-8 sm:w-6 sm:h-6 ${
                        openState ? "lg:w-10 lg:h-10" : "lg:w-8 lg:h-6"
                    }`}
                />
                <span className={`${openState ? "hidden" : "block"} ml-3`}>
                    {label}
                </span>
            </div>
        </li>
    );
}
