import React from 'react';
import { Button } from '../Button/Button';
import { PiTrayArrowDown, PiTrayArrowUp } from 'react-icons/pi';
import { useTranslation } from 'react-i18next';

export function Table({ items, archive }) {
    const { t } = useTranslation();

    return (
        <div className="flex flex-row w-full overflow-y-auto">
            <table className="w-full mx-5 lg:mx-10 mt-8 p-4">
                <tbody>
                    {items.map((item, index) => (
                        <React.Fragment key={index}>
                            {item.status === t("archived") ? (
                                <tr className="bg-red-200 overflow-hidden items-center">
                                    <td className="px-2 lg:px-4 py-4 lg:py-6 rounded-l-lg">
                                        <div className="flex flex-row items-center">
                                            <p className="max-w-[100px] lg:max-w-[200px] truncate">{item.name}</p>
                                            <div className={"block lg:hidden ml-2 w-2 h-2 rounded-full bg-red-500"} />
                                        </div>
                                    </td>
                                    <td className="px-2 lg:px-4 py-4 lg:py-6">{item.role}</td>
                                    <td className="hidden lg:flex flex-row px-2 lg:px-4 py-4 lg:py-6 items-center ">
                                        <div className="w-2 h-2 bg-red-500 rounded-full mr-2"></div>
                                        {item.status}
                                    </td>
                                    <td className="rounded-r-lg">
                                        <Button
                                            children={<PiTrayArrowUp className="w-6 h-6" />}
                                            className={"flex bg-red-300 rounded-lg items-center justify-center p-2"}
                                            onClick={() => (archive(item))}
                                        />
                                    </td>
                                </tr>
                            ) : (
                                <tr className="bg-white overflow-hidden items-center">
                                    <td className="px-2 lg:px-4 py-4 lg:py-6 rounded-l-lg">
                                        <div className="flex flex-row items-center">
                                            <p className="max-w-[100px] lg:max-w-[200px] truncate">{item.name}</p>
                                            <div className={`block lg:hidden ml-2 w-2 h-2 rounded-full ${item.status === t("active") ? "bg-green-500" : "bg-h2v-yellow"}`} />
                                        </div>
                                    </td>
                                    <td className="px-2 lg:px-4 py-4 lg:py-6">{item.role}</td>
                                    <td className="hidden lg:flex flex-row px-2 lg:px-4 py-4 lg:py-6 items-center ">
                                        <div className={`w-2 h-2 rounded-full mr-2 ${item.status === t("active") ? "bg-green-500" : "bg-h2v-yellow"}`}></div>
                                        {item.status}
                                    </td>
                                    <td className="rounded-r-lg">
                                        {item.id === sessionStorage.getItem("id") ? null :
                                            (
                                                <Button
                                                    children={<PiTrayArrowDown className={`w-6 h-6 ${item.status === t("awaitingConfirmation") ? "opacity-70" : "opacity-100"}`} />}
                                                    className={`flex bg-gray-200 rounded-lg items-center justify-center p-2 ${item.status === t("awaitingConfirmation") ? "opacity-45" : "opacity-100"}`}
                                                    disabled={item.status === t("awaitingConfirmation") ? true : false}
                                                    onClick={() => (archive(item))} />
                                            )
                                        }
                                    </td>
                                </tr>
                            )}
                            <tr className="h-4"></tr>
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
        </div>
    );
}
