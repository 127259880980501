import { I18n } from "../components/I18n/I18n.jsx"
import { useTranslation } from "react-i18next"
import { Sidebar } from "../components/Sidebar/Sidebar.jsx"
import { Input } from "../components/Input/index.jsx"
import { Button } from "../components/Button/Button.jsx"
import { Table } from "../components/Table/Table.jsx"
import { Modal } from "../components/Modal/index.jsx"
import { Select } from "../components/Select/Select.jsx"
import { Toast } from "../components/Toast/Toast.jsx"

import { addUser, archiveUser, listUsers, updateUser } from '../features/user/userActions.js'
import { changeOpenState } from '../features/sideBar/sideBarSlice.js'
import { useDispatch, useSelector } from 'react-redux'
import { MdSearch, MdOutlineClose, MdMenu, MdAdd } from "react-icons/md"
import { useState, useMemo, useCallback, useEffect } from "react"
import { resetAddingUserSuccess, resetArchivingSuccess, resetUpdatingUserSuccess } from "../features/user/userSlice.js"
import { formatPhoneNumber, removePhoneFormatting } from "../utils/utils.js"


export function CollaboratorPage() {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const changeState = () => {
        dispatch(changeOpenState())
    }

    useEffect(() => {
        dispatch(listUsers())
    }, [dispatch])

    const { id } = useSelector(
        (state) => state.auth
    )

    const { users, success, error } = useSelector(
        (state) => state.user
    )

    const [isAdding, setAddingNewCollaborator] = useState(false)
    const [isEditting, setIsEditting] = useState(null)
    const [archived, setArchiveCollaborator] = useState(null)
    const [selectedFilter, setSelectedFilter] = useState("all")
    const [search, setSearch] = useState("")

    const [name, setName] = useState("")
    const [phone, setPhone] = useState("")
    const [perfil, setPerfil] = useState("")
    const [status, setStatus] = useState("")

    const [message, setMessage] = useState(null)
    const [toastIsOpen, setOpenToast] = useState(false)
    const [toastStatus, setToastStatus] = useState(null)
    const [reset, setReset] = useState(null)

    const parsedUsers = users.map(user => {
        const role = user.role === "ADMINISTRADOR" ? t("administrator") : t("operator")

        let status

        if (user.status === "ATIVO") {
            status = t("active")
        } else if (user.status === "AGUARDANDO CONFIRMAÇÃO") {
            status = t("awaitingConfirmation")
        } else {
            status = t("archived")
        }
        return {
            ...user,
            role: role,
            status: status,
        }
    })

    const filters = useMemo(() => [
        { value: "active", label: t("actives") },
        { value: "all", label: t("all") },
        { value: "archived", label: t("archiveds") },
        { value: "operator", label: t("operator") },
        { value: "administrator", label: t("administrator") }
    ], [t]);

    const roles = useMemo(() => [
        { value: "operator", label: t("operator") },
        { value: "administrator", label: t("administrator") }
    ], [t]);

    const archiveCallback = useCallback((itemIndex) => {
        const userToUpdate = parsedUsers[itemIndex];
        const updatedStatus = userToUpdate.status === t("active") ? "ARQUIVADO" : "ATIVO";
        dispatch(archiveUser({ id: userToUpdate.id, status: updatedStatus })).then(() => dispatch(listUsers()))
    }, [parsedUsers, t, dispatch]);

    const openEdit = useCallback((user) => {
        setArchiveCollaborator(null);
        setName(user.name)
        setPerfil(user.role)
        setStatus(user.status)
        setPhone(user.phone)
        setIsEditting(user);
    }, []);

    const editUser = (e) => {
        e.preventDefault();

        const parsedPerfil = perfil === "administrator" ? "ADMINISTRADOR" : "OPERADOR"

        dispatch(updateUser({ id: isEditting.id, name, phone: removePhoneFormatting(phone), role: parsedPerfil })).then(() => dispatch(listUsers()))
    }


    const addNewOperator = (e) => {
        e.preventDefault();

        const name = e.target.elements.name.value
        const email = e.target.elements.email.value
        const phone = e.target.elements.phone.value
        const perfil = e.target.elements.perfils.value

        const parsedPerfil = perfil === "administrator" ? "ADMINISTRADOR" : "OPERADOR"

        dispatch(addUser({ name, email, phone: removePhoneFormatting(phone), role: parsedPerfil })).then(() => dispatch(listUsers()))
    }

    const filteredUsersBySelect = useMemo(() => {
        return parsedUsers.filter(user => selectedFilter === "all" || user.role.toLowerCase() === t(selectedFilter).toLowerCase() || user.status.toLowerCase() === t(selectedFilter).toLowerCase())
    }, [parsedUsers, t, selectedFilter]);

    const filteredUsers = useMemo(() => {
        return search.length > 0 ? filteredUsersBySelect.filter(user => user.name.toLowerCase().includes(search.toLowerCase())) : [];
    }, [filteredUsersBySelect, search]);

    const showToast = (status, message, resetFunc) => {
        setMessage(message)
        setReset(() => resetFunc)
        setToastStatus(status)
        setOpenToast(true)
    }

    useEffect(() => {
        if (success.archiving) {
            setArchiveCollaborator(null)
            dispatch(listUsers())
            showToast("success", t("archiveSuccess"), resetArchivingSuccess)
            dispatch(resetArchivingSuccess())
        }

        if (success.updatingUser) {
            showToast("success", t("updateUserSuccess"), resetUpdatingUserSuccess)
            setIsEditting(null);
            setName("");
            setPerfil("");
            setStatus("");
            setPhone("");
            dispatch(listUsers());
        }

        if (success.addingUser) {
            showToast("success", t("addUserSuccess"), resetAddingUserSuccess);
            setAddingNewCollaborator(false);
            setPhone("")
            dispatch(listUsers());
        }

        if (error?.status === 401) {
        }

    }, [success, error, setIsEditting, t, setAddingNewCollaborator, dispatch])

    return (
        <div className="flex bg-h2v-background h-screen overflow-hidden">
            <Sidebar />
            {toastIsOpen ? <Toast status={toastStatus} message={message} reset={reset} hide={setOpenToast} /> : null}
            <div className="flex flex-col w-full h-full overflow-y-auto overflow-x-hidden">
                <div className="flex items-center px-6 lg:px-12 justify-between py-6">
                    <div className="flex flex-row items-center">
                        <MdMenu className="mr-2 w-6 h-6 block lg:hidden" onClick={() => changeState()} />
                        <h1 className="text-black font-poppins font-medium text-base lg:text-h2vtext">{t("collaborators")}</h1>
                    </div>
                    <div className="flex items-center">
                        <I18n />
                        <Button
                            children={
                                <div>
                                    <p className="hidden lg:block">{t("addCollaborator")}</p>
                                    <MdAdd className="block lg:hidden" />
                                </div>}
                            onClick={() => setAddingNewCollaborator(true)}
                            className="ml-4 bg-h2v-lime-green lg:w-56 text-white font-poppins font-medium text-h2vsmalltext px-3 py-3 rounded-xl"
                        />
                    </div>
                </div>
                <div className="px-6 lg:px-12 pt-2">
                    <div className="border-t border-gray-300 w-full" />
                </div>
                <div className="flex flex-col lg:flex-row bg-white mx-5 lg:mx-10 mt-4 p-4 rounded-xl lg:items-center lg:justify-between lg:space-x-6">
                    <Input.Root>
                        <Input.Content
                            id="text"
                            type="text"
                            className={"w-full"}
                            placeholder={t("search")}
                            onChange={e => setSearch(e.target.value)}
                            value={search}
                            icon={<MdSearch className="pointer-events-none font-poppins w-6 h-6 absolute top-1/2 transform -translate-y-1/2 left-3" />}
                        />
                    </Input.Root>

                    <div className="flex flex-row items-center justify-between lg:space-x-6 pt-2 lg:pt-0">
                        {selectedFilter === "all" ? null : (
                            <Button
                                children={
                                    <span className="flex flex-row items-center">
                                        <p className="truncate mr-1 max-w-[80px] sm:max-w-full lg:max-w-full">
                                            {filters.find(filter => filter.value === selectedFilter).label}
                                        </p>
                                        <p className="ml-auto">
                                            ({filteredUsersBySelect.length})
                                        </p>
                                        <span className="ml-6">
                                            <MdOutlineClose className="pointer-events-none font-poppins text-white w-5 h-5 absolute top-1/2 transform -translate-y-1/2 right-2" />
                                        </span>
                                    </span>
                                }
                                className={"bg-h2v-lime-green text-white rounded-full h-8 text-left px-3"}
                                onClick={() => setSelectedFilter("all")}
                            />
                        )}
                        <div className="flex-grow lg:hidden"></div>
                        <Select
                            id="filters"
                            options={filters}
                            value={selectedFilter}
                            onChange={(e) => { setSelectedFilter(e.target.value) }}
                            className="lg:w-44 text-sm font-poppins font-bold lg:text-base border-h2v-green text-h2v-green focus:border-h2v-lime-green ml-auto"
                            iconColor="text-h2v-green"
                        />
                    </div>
                </div>
                <div className="flex-1 overflow-y-scroll no-scrollbar">
                    <Table
                        items={
                            (search.length > 0 ? filteredUsers : filteredUsersBySelect).sort((a, b) => {
                                if (a.id === id) return -1;
                                if (b.id === id) return 1;
                                return a?.name?.toUpperCase() > b?.name?.toUpperCase() ? 1 : -1;
                            })
                        }
                        archive={setArchiveCollaborator}
                    />
                </div>
            </div>

            {isAdding && (
                <Modal.Root>
                    <Modal.Content
                        iconButton={
                            <Button
                                className="flex bg-gray-200 rounded-lg items-center justify-center p-2 lg:p-4"
                                onClick={() => { setAddingNewCollaborator(false); setPhone("") }}
                                children={<MdOutlineClose />}
                            />
                        }
                    >
                        <Modal.Title text={t("addNewOperator")} />
                        <Modal.Form onSubmit={addNewOperator}>
                            <div className="flex flex-col space-y-0 lg:space-y-3">
                                <Input.Root>
                                    <Input.Label text={t("name")} />
                                    <Input.Content id="name" type="text" />
                                </Input.Root>
                                <Input.Root>
                                    <Input.Label text={t("email")} />
                                    <Input.Content id="email" type="email" />
                                </Input.Root>
                                <div className="grid grid-cols-2 gap-4">
                                    <Input.Root>
                                        <Input.Label text={t("phone")} />
                                        <Input.Content id="phone" type="text" value={formatPhoneNumber(phone)} onChange={(e) => setPhone(e.target.value)} />
                                    </Input.Root>
                                    <div>
                                        <label htmlFor="perfils" className="text-h2v-label font-poppins text-sm font-light">
                                            {t("perfil")}
                                        </label>
                                        <Select
                                            id="perfils"
                                            options={roles}
                                            className="appearance-none bg-white p-2 w-full placeholder-h2v-input text-base text-h2v-input block border-2 border-gray-300 focus:outline-none focus:border-h2v-focus rounded-lg"
                                            iconColor="text-h2v-label"
                                        />
                                    </div>
                                </div>
                            </div>
                            <Modal.Bottom>
                                <Button
                                    children={t("no")}
                                    className="self-center bg-h2v-orange font-poppins text-base text-white font-medium py-2 px-4 w-32 lg:w-48 rounded-lg"
                                    onClick={() => { setAddingNewCollaborator(false); setPhone("") }}
                                />
                                <Button
                                    type="submit"
                                    children={t("register")}
                                    className="self-center bg-h2v-green font-poppins text-base text-white font-medium py-2 px-4 w-32 lg:w-48 rounded-lg"
                                />
                            </Modal.Bottom>
                        </Modal.Form>
                    </Modal.Content>
                </Modal.Root>
            )}

            {archived !== null && (
                <Modal.Root>
                    <Modal.Content
                        iconButton={
                            <Button
                                className="flex bg-gray-200 rounded-lg items-center justify-center p-2 lg:p-4"
                                onClick={() => setArchiveCollaborator(null)}
                                children={<MdOutlineClose />}
                            />
                        }
                    >
                        <Modal.Title text={t("collaboratorInfo")} />
                        <div className="grid grid-cols-2 gap-4 pt-4">
                            <div className="space-y-4">
                                <Input.Root>
                                    <Input.Content value={archived.name} disabled={true} className={"border-0"} />
                                </Input.Root>
                                <Input.Root>
                                    <Input.Content value={formatPhoneNumber(archived.phone)} disabled={true} className={"border-0"} />
                                </Input.Root>
                                <div className="flex flex-row items-center">
                                    <div className="w-2 h-2 bg-green-500 rounded-full mr-2" />
                                    {t(archived.status)}
                                </div>
                            </div>
                            <div className="space-y-4 pb-4">
                                <Input.Root>
                                    <Input.Content value={t(archived.role)} disabled={true} className={"border-0"} />
                                </Input.Root>
                                <Input.Root>
                                    <Input.Content value={archived.email} disabled={true} className={"border-0"} />
                                </Input.Root>
                                <p className="text-end text-h2v-green text-sm underline underline-offset-2 cursor-pointer">
                                    {t("resendEmail")}
                                </p>
                            </div>
                        </div>

                        <Modal.Bottom>
                            <Button
                                children={archived.status === t("active") ? t("archive") : t("desarchive")}
                                className="self-center bg-h2v-red font-poppins text-base text-white font-medium py-2 px-4 w-32 lg:w-48 rounded-lg"
                                onClick={() => {
                                    const index = users.findIndex(item => item.name === archived.name);
                                    archiveCallback(index);
                                }}
                            />
                            <Button
                                children={t("edit")}
                                className="self-center bg-h2v-green font-poppins text-base text-white font-medium py-2 px-4 w-32 lg:w-48 rounded-lg"
                                onClick={() => openEdit(archived)}
                            />
                        </Modal.Bottom>
                    </Modal.Content>
                </Modal.Root>
            )}

            {isEditting !== null && (
                <Modal.Root>
                    <Modal.Content
                        iconButton={
                            <Button
                                className="flex bg-gray-200 rounded-lg items-center justify-center p-2 lg:p-4"
                                onClick={() => { setIsEditting(null); setPhone("") }}
                                children={<MdOutlineClose />}
                            />
                        }
                    >
                        <Modal.Title text={t("editOperatorInfo")} />
                        <Modal.Form onSubmit={editUser}>
                            <div className="lg:w-full lg:flex lg:flex-col sm:grid sm:grid-cols-2 sm:gap-3">
                                <Input.Root>
                                    <Input.Label text={t("name")} />
                                    <Input.Content value={name} onChange={(e) => setName(e.target.value)} placeholder={t("name")} />
                                </Input.Root>
                                <Input.Root>
                                    <Input.Label text={t("status")} />
                                    <Input.Content value={t(status)} disabled={true} placeholder={t("status")} />
                                </Input.Root>
                                <Input.Root>
                                    <Input.Label text={t("phone")} />
                                    <Input.Content value={formatPhoneNumber(phone)} onChange={(e) => setPhone(e.target.value)} placeholder={t("phone")} />
                                </Input.Root>
                                <div>
                                    <label htmlFor="perfils" className="text-h2v-label font-poppins text-sm font-light">
                                        {t("perfil")}
                                    </label>
                                    <Select
                                        id="perfils"
                                        options={roles}
                                        className="appearance-none bg-white p-2 w-full placeholder-h2v-input lg:text-base text-h2v-input block border-2 border-gray-300 focus:outline-none focus:border-h2v-focus rounded-lg"
                                        iconColor="text-h2v-label"
                                    />
                                </div>
                            </div>
                            <Modal.Bottom>
                                <Button
                                    children={t("cancel")}
                                    className="self-center bg-h2v-orange font-poppins text-base text-white font-medium py-2 px-4 w-32 lg:w-48 rounded-lg"
                                    onClick={() => { setIsEditting(null); setPhone("") }}
                                />
                                <Button
                                    type="submit"
                                    children={t("save")}
                                    className="self-center bg-h2v-green font-poppins text-base text-white font-medium py-2 px-4 w-32 lg:w-48 rounded-lg"
                                />
                            </Modal.Bottom>
                        </Modal.Form>
                    </Modal.Content>
                </Modal.Root>
            )}
        </div>
    );
}
