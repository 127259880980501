import { createSlice } from "@reduxjs/toolkit";

const initialState = { openState: false }

const sideBarSlice = createSlice({
    name: "sideBar",
    initialState,
    reducers: {
        changeOpenState: (state) => {
            state.openState = !state.openState
        },
    }
})

export const { changeOpenState } = sideBarSlice.actions
export default sideBarSlice.reducer