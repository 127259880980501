/**
 * Validate if an email address is in a valid format.
 * @param {string} email - The email address to validate.
 * @returns {boolean} - True if valid, false otherwise.
 */
export function validateEmail(email)  {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
}

export function formatPhoneNumber(value) {
    if (!value) return value;

    const phoneNumber = value.replace(/\D/g, '');

    const fixedNumber = phoneNumber.slice(0, 11);

    if (fixedNumber.length <= 10) {
        return fixedNumber.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
    } else {
        return fixedNumber.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
    }
}

export function removePhoneFormatting(value) {
    if (!value) return value;

    return value.replace(/\D/g, '');
}
