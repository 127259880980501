import agent from "./api"

const AUTH = "/auth"

export async function login(email, password) {
    return await agent.post(AUTH + "/signin", { email: email, password: password })
}

export async function refreshToken(refreshToken) {
    return await agent.post(AUTH + "/refresh-token", { refreshToken: refreshToken })
}

export async function changePassword(oldPassword, newPassword, newPasswordConfirmation) {
    return await agent.patch(AUTH + "/change-password", {
        oldPassword: oldPassword,
        newPassword: newPassword,
        newPasswordConfirmation: newPasswordConfirmation,
    })
}

export async function forgotPassword(email) {
    return await agent.post(AUTH + "/forgot-password", {email: email})
}

export async function validateCode(code) {
    return await agent.post(AUTH + "/validate-code", {confirmationCode: code})
}

export async function resetPassword(token, newPassword, newPasswordConfirmation) {
    return await agent.patch(AUTH + "/reset-password/" + token, {newPassword: newPassword, newPasswordConfirmation: newPasswordConfirmation})
}