import React from 'react';

export function  Pill ({ leadingIcon, trailingIcon, children }) {
  return (
    <div className={`h-12 flex items-center border-2 border-h2v-blue text-h2v-blue rounded-pill px-4 py-2`}>
      {leadingIcon && leadingIcon}
      <span className="flex-1">{children}</span>
      {trailingIcon && trailingIcon}
    </div>
  );
};

export default Pill;