import { CodeInput } from "./CodeInput"
import { InputContent } from "./InputContent"
import { InputLabel } from "./InputLabel"
import {InputRoot} from "./InputRoot"

export const Input = {
    Root: InputRoot,
    Label: InputLabel,
    Content: InputContent,
    Code: CodeInput,
}