import { createAsyncThunk } from "@reduxjs/toolkit"
import { getUserByID, getUsers, createUser, updateUserByID, updateEmailByID, archiveUserByID} from "../../api/users"

export const listUsers = createAsyncThunk(
    'user/getUsers',
    async (_, { rejectWithValue }) => {
        try {
            const response = await getUsers()
            return response.data
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || error.message)
        }
    }
)

export const addUser = createAsyncThunk(
    'user/postUser',
    async ({name, email, phone, role}, { rejectWithValue }) => {
        try {
            const response = await createUser(name, email, phone, role)
            return response.data
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || error.message)
        }
    }
)

export const getUser = createAsyncThunk(
    'user/getUser',
    async ({id}, { rejectWithValue }) => {
        try {
            const response = await getUserByID(id)
            return response.data
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || error.message)
        }
    }
)

export const updateUser = createAsyncThunk(
    'user/updateUser',
    async ({id, name, phone, role}, { rejectWithValue }) => {
        try {
            const response = await updateUserByID(id, name, phone, role)
            return response.data
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || error.message)
        }
    }
)

export const updateUserEmail = createAsyncThunk(
    'user/updateEmail',
    async ({id, email}, { rejectWithValue }) => {
        try {
            const response = await updateEmailByID(id, email)
            return response.data
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || error.message)
        }
    }
)

export const archiveUser = createAsyncThunk(
    'user/archiveUser',
    async ({id, status}, { rejectWithValue }) => {
        try {
            const response = await archiveUserByID(id, status)
            return response.data
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || error.message)
        }
    }
)
