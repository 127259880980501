import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const chartAreaBorder = {
    id: 'chartAreaBorder',
    beforeDraw(chart, args, options) {
        const { ctx, chartArea: { left, top, width, height } } = chart;
        ctx.save();
        ctx.strokeStyle = options.borderColor;
        ctx.lineWidth = options.borderWidth;
        ctx.setLineDash(options.borderDash || []);
        ctx.lineDashOffset = options.borderDashOffset;
        ctx.strokeRect(left, top, width, height);
        ctx.restore();
    }
};

export function LinePointedChart({ data, min, max, stepSize, color, fillColor }) {
    const chartRef = useRef(null);

    useEffect(() => {
        const ctx = chartRef.current.getContext('2d');

        const linePointedChart = new Chart(ctx, {
            type: 'line',
            data: {
                labels: data,
                datasets: [
                    {
                        data: data,
                        fill: true,
                        borderColor: color,
                        borderWidth: 3,
                        backgroundColor: fillColor,
                        tension: 0.4,
                        pointBackgroundColor: color,
                        pointBorderWidth: 3,
                    },
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                clip: false,
                scales: {
                    y: {
                        beginAtZero: true,
                        min: min,
                        max: max,
                        ticks: {
                            crossAlign: "far",
                            stepSize: stepSize,
                            display: true,
                        },
                        border: {
                            borderColor: 'rgba(189, 189, 189, 1)',
                            dashOffset: 2,
                            dash: [5, 5],
                        },
                        grid: {
                            color: 'rgba(189, 189, 189, 1)',
                            drawTicks: false,
                        },
                    },
                    y2: {
                        beginAtZero: true,
                        min: min,
                        max: max,
                        position: 'right',
                        ticks: {
                            stepSize: stepSize / 2,
                            display: false, // No labels for these ticks
                        },
                        border: {
                            borderColor: 'rgba(189, 189, 189, 1)',
                            dashOffset: 2,
                            dash: [5, 5],
                        },
                        grid: {
                            color: 'rgba(189, 189, 189, 1)',
                            drawTicks: false,
                        },
                    },
                    x: {
                        ticks: {
                            display: false,
                        },

                        grid: {
                            display: false,
                        },

                    }
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                    chartAreaBorder: {
                        borderColor: 'rgba(189, 189, 189, 1)',
                        borderDash: [5, 5],
                        borderDashOffset: 2,
                    },
                },
            },
            plugins: [chartAreaBorder],
        });

        return () => {
            linePointedChart.destroy(); // Cleanup the chart on component unmount
        };
    }, [data, min, max, stepSize, color, fillColor]);

    return (
        <div className="w-full h-36 overflow-hidden">
            <canvas ref={chartRef} />
        </div>
    );
};

export default LinePointedChart;
