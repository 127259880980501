import { useState } from 'react'
import { MdLogout } from 'react-icons/md'
import { useSelector } from 'react-redux'

export function LogoutButton({ onClick, label }) {
    const [hovered, setIsHovered] = useState(false)

    const { openState } = useSelector((state) => state.sideBar)

    return (
        <div className="flex items-center">
            <div
                className={`flex w-full p-2 rounded-md cursor-pointer font-poppins font-medium text-white ${hovered ? 'hover:bg-white hover:text-h2v-green' : ''
                    }`}
                onClick={onClick}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <MdLogout className={`w-8 h-8 sm:w-6 sm:h-6 ${openState ? "lg:w-10 lg:h-10" : "lg:w-8 lg:h-6"
                    }`} />
                <span className={`${openState ? "hidden" : "block"} ml-3`}>
                    {label}
                </span> 
            </div>
        </div>
    )
}
