import { useLocation, Navigate } from "react-router-dom"

export function PrivateRoute ({ children }) {
  const isLoggedIn = sessionStorage.getItem("accessToken") !== null
  const location = useLocation()

  return isLoggedIn ? (
    <>{children}</>
  ) : (<Navigate
    to="/"
    replace={true}
    state={{ from: location.pathname + location.search }}
  />)
}