import { MdKeyboardArrowDown } from "react-icons/md"
import { twJoin, twMerge } from "tailwind-merge"

export function Select({ id, options, className, iconColor, ...props }) {
    return (
        <div className="relative">
            <select id={id} name={id} className={twMerge(
                "appearance-none cursor-pointer bg-white border py-2 pl-2 pr-8 rounded leading-tight focus:outline-none focus:bg-white",
                className
            )} {...props}>
                {options.map((option, index) => (
                    <option key={index} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
            <MdKeyboardArrowDown className={twJoin("pointer-events-none w-6 h-6 absolute top-1/2 transform -translate-y-1/2 right-2",
                iconColor)} />
        </div>
    )
}