import { createSlice } from "@reduxjs/toolkit"
import {
    userChangePassword,
    userForgotPassword,
    userLogin,
    userLogout,
    userValidateCode,
    userResetPassword
} from "./authActions"

const id = sessionStorage.getItem("id")
const accessToken = sessionStorage.getItem("accessToken")
const refreshToken = sessionStorage.getItem("refreshToken")

const initialState = {
    loading: {
        login: false,
        forgotPassword: false,
        changePassword: false,
        validateCode: false,
        resetPassword: false,
    },
    firstAccess: null,
    id,
    accessToken,
    refreshToken,
    error: null,
    success: {
        login: false,
        forgotPassword: null,
        changePassword: false,
        validateCode: false,
        resetPassword: false,
    },
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        resetErrorState: (state) => {
            state.error = null
        },
        resetValidationState: (state) => {
            state.success.forgotPassword = null
        },
        resetSuccessState: (state) => {
            state.success = initialState.success
        },
        resetResetPasswordState: (state) => {
            state.success.resetPassword = false
            state.error = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(userLogin.pending, (state) => {
                state.loading.login = true
                state.error = null
            })
            .addCase(userLogin.fulfilled, (state, action) => {
                const { data, id } = action.payload

                state.loading.login = false
                state.id = id
                state.accessToken = data.accessToken
                state.refreshToken = data.refreshToken
                state.firstAccess = data.firstAccess
                state.success.login = true
            })
            .addCase(userLogin.rejected, (state, action) => {
                state.loading.login = false
                state.error = action.payload
                state.success.login = false
            })
            .addCase(userLogout, (state) => {
                state.success = initialState.success
                state.accessToken = null
                state.refreshToken = null
                state.firstAccess = null
                state.id = null
                state.error = null
                sessionStorage.clear()
            })
            .addCase(userForgotPassword.pending, (state) => {
                state.loading.forgotPassword = true
                state.error = null
            })
            .addCase(userForgotPassword.fulfilled, (state, action) => {
                state.loading.forgotPassword = false
                state.success.forgotPassword = action.payload.email
            })
            .addCase(userForgotPassword.rejected, (state, action) => {
                state.loading.forgotPassword = false
                state.error = action.payload
                state.success.forgotPassword = null
            })
            .addCase(userValidateCode.pending, (state) => {
                state.loading.validateCode = true
                state.error = null
            })
            .addCase(userValidateCode.fulfilled, (state) => {
                state.loading.validateCode = false
                state.success.validateCode = true
            })
            .addCase(userValidateCode.rejected, (state, action) => {
                state.loading.validateCode = false
                state.success.validateCode = false
                state.error = action.payload
            })
            .addCase(userChangePassword.pending, (state) => {
                state.loading.changePassword = true
                state.error = null
            })
            .addCase(userChangePassword.fulfilled, (state) => {
                state.loading.changePassword = false
                state.success.changePassword = true
            })
            .addCase(userChangePassword.rejected, (state, action) => {
                state.loading.changePassword = false
                state.success.changePassword = false
                state.error = action.payload
            })
            .addCase(userResetPassword.pending, (state) => {
                state.loading.resetPassword = true
                state.error = null
            })
            .addCase(userResetPassword.fulfilled, (state) => {
                state.loading.resetPassword = false
                state.success.resetPassword = true
            })
            .addCase(userResetPassword.rejected, (state, action) => {
                state.loading.resetPassword = false
                state.success.resetPassword = false
                state.error = action.payload
            })
    },
})

export const { resetSuccessState, resetErrorState, resetValidationState, resetResetPasswordState } = authSlice.actions
export default authSlice.reducer
