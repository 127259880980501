import React from "react"


export function ModalRoot({ children }) {
    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity" aria-hidden="true"></div>
            <div className="relative z-20 flex items-center justify-center w-96 lg:w-full p-4 text-center sm:items-center sm:p-0">
                <div className="relative transform overflow-hidden rounded-2xl bg-h2v-background text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl">
                    {children}
                </div>
            </div>
        </div>
    )
}