import { Button } from "./components/Button/Button.jsx"
import { Input } from "./components/Input/index.jsx"
import { Modal } from "./components/Modal/index.jsx"
import { useState } from "react"
import { MdOutlineClose } from "react-icons/md"


function App() {
    const [firstIsOpen, setfirstIsOpen] = useState(false)
    const [secondIsOpen, setSecondIsOpen] = useState(false)
    return (
        <main>
            <div className="h-screen flex items-center justify-center">
                <div className="space-x-8">
                    <Button onClick={() => setfirstIsOpen(true)} className={"btn-primary"} children={"Abrir Verde"} ></Button>
                    <Button onClick={() => setSecondIsOpen(true)} className={"btn-secondary"} children={"Abrir Laranja"} ></Button>
                </div>
                {firstIsOpen && <Modal.Root>
                    <Modal.Content>
                        <Modal.Title text={<span className="font-bold">Atenção</span>} />
                        <Modal.Text text={<div>Deseja fazer alterações no seu <span className="font-bold">perfil</span> ?</div>} />
                        <Modal.Bottom>
                            <Button onClick={() => setfirstIsOpen(false)} className={"btn-secondary"} children={"Não"}></Button>
                            <Button onClick={() => setfirstIsOpen(false)} className={"btn-primary"} children={"Sim"}></Button>
                        </Modal.Bottom>
                    </Modal.Content>
                </Modal.Root>}
                {secondIsOpen && <Modal.Root>
                    <Modal.Content iconButton={<Button className={"flex bg-gray-200 rounded-lg items-center justify-center p-4"} onClick={() => setSecondIsOpen(false)} children={<MdOutlineClose/>} />}>
                        <Modal.Title text={"Editar informações do operador"} />
                        <Modal.Form>
                            <Input.Root>
                                <Input.Label id="name" text={"Nome"} />
                                <Input.Content id="name" type="text" placeholder="Nome" />
                            </Input.Root>
                            <Input.Root>
                                <Input.Label id="situation" text={"Situação"} />
                                <Input.Content id="situation" type="text" placeholder="Situação" />
                            </Input.Root>
                            <Input.Root>
                                <Input.Label id="email" text={"Email"} />
                                <Input.Content id="email" type="email" placeholder="Email" />
                            </Input.Root>
                            <Input.Root>
                                <Input.Label id="phone" text={"Telefone"} />
                                <Input.Content id="phone" type="text" placeholder="Telefone" />
                            </Input.Root>
                        </Modal.Form>
                        <Modal.Bottom>
                            <Button onClick={() => setSecondIsOpen(false)} className={"btn-secondary"} children={"Cancelar"}></Button>
                            <Button onClick={() => setSecondIsOpen(false)} className={"btn-primary"} children={"Salvar"}></Button>
                        </Modal.Bottom>
                    </Modal.Content>
                </Modal.Root>}
            </div>
        </main>
    )
}

export default App