import { Button } from "../components/Button/Button"
import { Input } from "../components/Input/index.jsx"
import { ReactComponent as Logo } from "../assets/main_logo.svg"
import { t } from "i18next"
import { useSelector, useDispatch } from "react-redux"
import { useEffect, useState, useCallback } from "react"
import { userValidateCode } from "../features/auth/authActions.js"
import { useLocation, useNavigate } from "react-router-dom"
import { resetSuccessState, resetValidationState } from "../features/auth/authSlice.js"
import ClipLoader from "react-spinners/ClipLoader"

export function SecurityCodePage() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const { error, loading, success } = useSelector((state) => state.auth)
    const [code, setCode] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const handleCodeChange = useCallback((newCode) => {
        setCode(newCode)
        if (error) {
            setErrorMessage('')
        }
    }, [error])


    useEffect(() => {
        if (!location.state?.email) {
            navigate("/", { replace: true })
            return
        }

        dispatch(resetSuccessState())
    }, [location, navigate, dispatch])

    const sendCode = (e) => {
        e.preventDefault()
        if (code.length === 6) {
            dispatch(userValidateCode({ confirmationCode: code }))
                .unwrap()
                .then(() => {
                    navigate('/reset-password')
                    dispatch(resetValidationState())
                })
                .catch((err) => {
                    setErrorMessage(t("invalidCode"))
                })
        } else {
            setErrorMessage(t("codeLengthError"))
        }
    }

    useEffect(() => {
        if (success.validateCode) {
            navigate('/reset-password')
            dispatch(resetValidationState())
        }

        if (loading.validateCode) {
            setIsLoading(true)
        }

        if (error) {
            setErrorMessage(t("invalidCode"))
            setIsLoading(false)
        }
    }, [success, loading, error, navigate, dispatch])

    return (
        location.state?.email ?
            <div className="flex flex-col lg:flex-row h-screen w-screen overflow-hidden overflow-y-auto">
                <div className="flex flex-col w-full justify-center items-center p-8 lg:p-0">
                    <Logo className="w-32 lg:w-56 h-auto pb-5 lg:pb-10" />
                    <form className="flex flex-col space-y-6 w-1/2 max-w-md" onSubmit={sendCode}>
                        <h1 className="self-start font-poppins font-semibold text-3xl">{t("insertSecurityCode")}</h1>
                        <p className="pb-3">{`${t("insertSecurityCodeDescription")} ${location.state?.email}`}</p>

                        <Input.Root className="relative pb-4 flex flex-col items-center">
                            <Input.Code onChange={handleCodeChange} />
                            {errorMessage && (
                                <p className="absolute text-red-500 mt-12 text-center">{errorMessage}</p>
                            )}
                        </Input.Root>

                        <div className="self-center">
                            <Button
                                type="submit"
                                className="bg-h2v-green font-poppins text-base text-white font-medium py-2 px-4 w-48 rounded-lg flex items-center justify-center">
                                {isLoading ? <ClipLoader size={24} color="#ffffff" /> : t("send")}

                            </Button>
                        </div>
                    </form>
                </div>
            </div> : null
    )
}
