import Background from "../assets/background.svg"
import { ReactComponent as Logo } from "../assets/main_logo.svg"
import { Button } from "../components/Button/Button"
import { Input } from "../components/Input/index.jsx"
import { Modal } from "../components/Modal/index.jsx"
import { MdOutlineClose } from "react-icons/md"
import { userForgotPassword, userLogin } from '../features/auth/authActions'
import { useDispatch, useSelector } from 'react-redux'
import { validateEmail } from "../utils/utils.js"
import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Toast } from "../components/Toast/Toast.jsx"
import { resetErrorState } from "../features/auth/authSlice.js"
import ClipLoader from "react-spinners/ClipLoader"

export function LoginPage() {
    const [isOpen, setIsOpen] = useState(false)
    const navigate = useNavigate()
    const { t } = useTranslation()
    const [errorMessages, setErrorMessages] = useState({
        email: null,
        password: null,
        recoverEmail: null,
    })
    const [isLoginValid, setIsLoginValid] = useState(true)
    const [isRecoverValid, setIsRecoverValid] = useState(true)

    const [messageError, setMessage] = useState(null)
    const [toastIsOpen, setOpenToast] = useState(false)
    const [isLoginLoading, setisLoginLoading] = useState(false)
    const [isRecoveringLoading, setisRecoveringLoading] = useState(false)

    const { firstAccess, loading, error, success } = useSelector(
        (state) => state.auth
    )

    const dispatch = useDispatch()

    const handleLogin = (e) => {
        e.preventDefault()
        const email = e.target.elements.email.value
        const password = e.target.elements.password.value

        const errors = {}

        if (!email) {
            errors.email = t("emailIsRequired")
        } else if (!validateEmail(email)) {
            errors.email = t("invalidEmail")
        }

        if (!password) {
            errors.password = t("passwordIsRequired")
        }

        if (Object.keys(errors).length > 0) {
            setErrorMessages(errors)
            setIsLoginValid(false)
            return
        }

        setIsLoginValid(true)
        dispatch(userLogin({ email, password }))
    }

    const recoverPassword = (e) => {
        e.preventDefault()
        const email = e.target.elements.recoverEmail.value

        if (!validateEmail(email)) {
            setErrorMessages((prev) => ({ ...prev, recoverEmail: t("invalidEmail") }))
            setIsRecoverValid(false)
        } else {
            setIsRecoverValid(true)
            dispatch(userForgotPassword({ email })).unwrap()
            .then(() => {
                navigate('/validate', {state:{ email }});
            })
            .catch((err) => {

            });
        }
    }

    const showToast = (message) => {
        setMessage(message)
        setOpenToast(true)
    }

    useEffect(() => {
        if (loading.login) {
            setisLoginLoading(true)
        }

        if (loading.forgotPassword) {
            setisRecoveringLoading(true)
        }

        if (success.login) {
            if (firstAccess) navigate("/redefine-password")
            else navigate("/dashboard")
        }

        if (success.forgotPassword) {
            setIsOpen(false)
        }

        if (error?.status === 401) {
            setisLoginLoading(false)
            setErrorMessages({ email: t("userOrPasswordNotFound"), password: "" })
            setIsLoginValid(false)
            return
        }

        if (error?.status === 404) {
            setIsRecoverValid(false)
            setisRecoveringLoading(false)
            setErrorMessages((prev) => ({ ...prev, recoverEmail: t("invalidEmail") }))
            return
        }

        if (error) {
            setisLoginLoading(false)
            setisRecoveringLoading(false)
            showToast(error)
        }

    }, [navigate, success, loading, firstAccess, error, t, dispatch])

    return (
        <div className="flex h-screen w-screen overflow-hidden">
            {toastIsOpen ? <Toast message={messageError} reset={resetErrorState} hide={setOpenToast} /> : null}
            <div className="hidden lg:flex lg:w-1/3 h-full">
                <img src={Background} className="h-full w-full object-cover" alt="Background" />
            </div>
            <div className="flex flex-col w-full lg:w-5/6 justify-center items-center p-8 lg:p-0">
                <Logo className="w-40 lg:w-56 h-auto pb-10 lg:pb-20" />
                <form className="flex flex-col space-y-6 w-full max-w-md" onSubmit={handleLogin}>
                    <h1 className="self-start font-poppins font-semibold text-3xl pb-6">{t("login")}</h1>
                    <Input.Root className="relative pb-1">
                        <Input.Content
                            id="email"
                            type="email"
                            placeholder={t("email")}
                            onChange={() => { setIsLoginValid(true); setErrorMessages(prev => ({ ...prev, email: null })) }}
                            className={`border ${!isLoginValid && errorMessages.email ? "border-red-500 focus:border-red-500" : ""}`}
                        />
                        {errorMessages.email && (
                            <div className="absolute text-red-500 text-sm">{errorMessages.email}</div>
                        )}
                    </Input.Root>
                    <Input.Root className="relative pb-1">
                        <Input.Content
                            id="password"
                            type="password"
                            placeholder={t("password")}
                            onChange={() => { setIsLoginValid(true); setErrorMessages(prev => ({ ...prev, password: null })) }}
                            className={`border ${!isLoginValid && errorMessages.password !== null ? "border-red-500 focus:border-red-500" : ""}`}
                        />
                        {errorMessages && (
                            <div className="absolute text-red-500 text-sm">{errorMessages.password}</div>
                        )}
                    </Input.Root>
                    <div className="self-end">
                        <p className="cursor-pointer text-gray-600" onClick={() => setIsOpen(true)}>
                            {t("forgotThePassword")}
                        </p>
                    </div>
                    <div className="self-center">
                        <Button
                            type="submit"
                            className="bg-h2v-green font-poppins text-base text-white font-medium py-2 px-4 w-48 rounded-lg flex items-center justify-center"
                        >
                            {isLoginLoading ? <ClipLoader size={24} color="#ffffff" /> : t("signin")}
                        </Button>
                    </div>
                </form>
                {isOpen && (
                    <Modal.Root>
                        <Modal.Content iconButton={
                            <Button
                                className="flex bg-gray-200 rounded-lg items-center justify-center p-2 lg:p-4"
                                onClick={() => setIsOpen(false)}
                                children={<MdOutlineClose />}
                            />}>
                            <Modal.Title text={t("recoverYourPassword")}/>
                            <Modal.Text text={t("enterTheEmailRegisterd")} />
                            <Modal.Form onSubmit={recoverPassword}>
                                <Input.Root className="relative pb-1 lg:pt-0 sm:pt-2">
                                    <Input.Content
                                        id="recoverEmail"
                                        type="email"
                                        placeholder={t("email")}
                                        onChange={() => { setIsRecoverValid(true); setErrorMessages({}) }}
                                        className={`border ${!isRecoverValid && errorMessages.recoverEmail ? "border-red-500 focus:border-red-500" : ""}`}
                                    />
                                    {!isRecoverValid && errorMessages.recoverEmail && (
                                        <div className="absolute text-red-500 text-sm">{errorMessages.recoverEmail}</div>
                                    )}
                                </Input.Root>
                                <Modal.Bottom>
                                    <Button type="submit" className="bg-h2v-green font-poppins text-base text-white font-medium py-2 px-4 w-48 rounded-lg flex items-center justify-center">
                                        {isRecoveringLoading ? <ClipLoader size={24} color="#ffffff" /> : t("recoverPassword")}
                                    </Button>
                                </Modal.Bottom>
                            </Modal.Form>
                        </Modal.Content>
                    </Modal.Root>
                )}
            </div>
        </div>
    )
}
