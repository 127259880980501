export function ModalContent({ children, iconButton }) {
    return (
        <div>
            <div className="flex justify-end pt-2 lg:pt-4 px-5">
                {iconButton}
            </div>
            <div className="bg-h2v-background px-8 lg:pb-8 sm:pt-2 lg:pt-5 sm:p-4">
                <div className="grid justify-items-center space-y-0 lg:space-y-8">
                    {children}
                </div>
            </div>
        </div>
    )
}