const ptBrTranslations = {
    translations: {
        login: "Login",
        email: "E-mail",
        password: "Senha",
        forgotThePassword: "Esqueci a senha",
        signin: "Entrar",
        recoverYourPassword: "Recupere a sua senha",
        enterTheEmailRegisterd: "Digite o e-mail cadastrado no sistema",
        recoverPassword: "Recuperar Senha",
        collaborators: "Colaboradores",
        addCollaborator: "Adicionar Colaborador",
        search: "Pesquisa",
        all: "Todos",
        operator: "Operador",
        administrator: "Administrador",
        role: "Função",
        active: "Ativo",
        actives: "Ativos",
        inactive: "Inativo",
        perfil: "Perfil",
        dashboard: "Dashboard",
        report: "Relatório",
        logout: "Sair",
        addNewOperator: "Adicionar novo operador",
        name: "Nome",
        phone: "Celular",
        status: "Status",
        no: "Não",
        register: "Cadastrar",
        redefinePassword: "Redefinir Senha",
        oldPassword: "Senha Antiga",
        newPassword: "Nova Senha",
        repeatPassword: "Repetir Senha",
        save: "Salvar",
        invalidEmail: "Endereço de e-mail inválido",
        invalidPassword: "Senha inválida",
        collaboratorInfo: "Informações do Colaborador",
        archive: "Arquivar",
        edit: "Editar",
        resendEmail: "Reenviar e-mail",
        desarchive: "Desarquivar",
        editOperatorInfo: "Editar informações do colaborador",
        editPerfilInfo: "Editar informações do perfil",
        cancel: "Cancelar",
        archived: "Arquivado",
        archiveds: "Arquivados",
        awaitingConfirmation: "Esperando confirmação",
        emailIsRequired: "E-mail é obrigatório",
        passwordIsRequired: "Senha é obrigatório",
        userOrPasswordNotFound: "Usuário e/ou senha não foram encontrados",
        newPasswordSameAsOld: "A senha deve ser diferente da antiga",
        passwordsDoNotMatch: "As senhas não coincidem",
        atLeast8Characters: "Pelo menos 8 caracteres",
        atLeastOneUppercaseLetter: "Pelo menos uma letra maiúscula",
        atLeastOneNumber: "Pelo menos um número",
        atLeastOneSpecialCharacter: "Pelo menos um caractere especial (!@#$%^&*)",
        january: "Janeiro",
        february: "Fevereiro",
        march: "Março",
        april: "Abril",
        may: "Maio",
        june: "Junho",
        july: "Julho",
        august: "Agosto",
        september: "Setembro",
        october: "Outubro",
        november: "Novembro",
        december: "Dezembro",
        photocatalyticPanel: "Painel Fotocatalítico",
        viewPeriod: "Visualizar Período",
        until: "até",
        reactor: "Reator",
        glycerolTank: "Tanque de Glicerol",
        lastCommands: "Últimos Comandos",
        productionVolume: "Volume de produção de H₂",
        seeAll: "Ver todos",
        gasFlow: "Fluxo de Gás",
        temperature: "Temperatura",
        synchronizationTime: "Tempo de Sincronização",
        waiting: "Aguardando",
        addUserSuccess: "Usuário adicionado com sucesso!",
        updateUserSuccess: "Usuário atualizado com sucesso!",
        insertSecurityCode: "Insira o código de segurança",
        send: "Enviar",
        invalidCode: "Código inválido",
        codeLengthError: "Erro no tamanho do código",
        changePassword: "Alterar Senha",
        insertSecurityCodeDescription: "Insira o código de 6 dígitos enviado para o seu e-mail:",
        archiveSuccess: "Arquivado/Desarquivado com sucesso!"
    }
}

export default ptBrTranslations