import { I18n } from "../components/I18n/I18n.jsx"
import { Sidebar } from "../components/Sidebar/Sidebar"
import { Select } from "../components/Select/Select"
import { LineChart } from "../components/Charts/LineChart"
import { LinePointedChart } from "../components/Charts/LinePointedChart"
import { Pill } from "../components/Pill/Pill"
import { useTranslation } from "react-i18next"
import Sun from "../assets/sun.svg"
import Moisture from "../assets/moisture.svg"
import { IoMdTime } from "react-icons/io"
import { IoSettingsOutline } from "react-icons/io5"
import { IoNotificationsOutline } from "react-icons/io5"
import { BsInfoCircle } from "react-icons/bs"
import { FaArrowRightLong } from "react-icons/fa6"
import React, { useState } from "react"

export function DashboardPage() {
    const { t } = useTranslation()

    const months = [
        { value: 'jan', label: t('january') },
        { value: 'feb', label: t('february') },
        { value: 'mar', label: t('march') },
        { value: 'apr', label: t('april') },
        { value: 'may', label: t('may') },
        { value: 'jun', label: t('june') },
        { value: 'jul', label: t('july') },
        { value: 'aug', label: t('august') },
        { value: 'sep', label: t('september') },
        { value: 'oct', label: t('october') },
        { value: 'nov', label: t('november') },
        { value: 'dec', label: t('december') }
    ]

    const d = new Date()
    const day = d.getUTCDate()
    const month = d.getUTCMonth()
    const year = d.getUTCFullYear()

    const [fromDay, setFromDay] = useState(day.toString())
    const [toDay, setToDay] = useState(day.toString())
    const [fromMonth, setFromMonth] = useState(months[month].value)
    const [toMonth, setToMonth] = useState(months[month].value)
    const [fromYear, setFromYear] = useState(year.toString())
    const [toYear, setToYear] = useState(year.toString())

    const commands = [
        { command: "Alteração da Bomba de Glicerol", from: 85, to: 100, status: t("waiting") },
        { command: "Alteração da Bomba de Glicerol", from: 85, to: 100, status: t("waiting") },
        { command: "Alteração da Bomba de Glicerol", from: 85, to: 100, status: t("waiting") },
    ]

    return (
        <div className="flex bg-h2v-dashboard-background h-screen overflow-hidden">
            <Sidebar />
            <div className="flex flex-col w-full h-full overflow-auto">
                <div className="flex flex-row bg-white mt-4 mx-4 p-4 px-6 rounded-xl items-center justify-between">
                    <div className="flex flex-row items-center space-x-4">
                        <Pill
                            leadingIcon={<img src={Sun} className="mr-2 object-cover" alt="Sun" />}
                            children={<p className="font-poppins font-medium text-h2vsmalltext">+38 ºC</p>}
                        />
                        <Pill
                            leadingIcon={<img src={Moisture} className="mr-2 object-cover" alt="Sun" />}
                            children={<p className="font-poppins font-medium text-h2vsmalltext">75%</p>}
                        />
                        <Pill
                            children={
                                <div className="flex flex-row items-center mr-2">
                                    <p className="font-poppins font-medium text-xs mr-2 text-center">
                                        {
                                            t("synchronizationTime").split(" ").length === 3 ?
                                                (
                                                    <>
                                                        {t("synchronizationTime").split(" ")[0] + " "}
                                                        {t("synchronizationTime").split(" ")[1]} <br />
                                                        {t("synchronizationTime").split(" ")[2]}
                                                    </>
                                                ) :
                                                (
                                                    <>
                                                        {t("synchronizationTime").split(" ")[0]}<br />
                                                        {t("synchronizationTime").split(" ")[1]}
                                                    </>
                                                )
                                        }
                                    </p>
                                    <p className="font-poppins font-medium text-h2vsmalltext">1 min</p>
                                </div>
                            }
                            trailingIcon={<IoMdTime className="w-6 h-6" />}
                        />
                    </div>
                    <div className="flex flex-row h-full items-center space-x-6">
                        <I18n />
                        <div className="flex flex-row h-full items-center space-x-3">
                            <IoSettingsOutline className="w-7 h-7 text-h2v-blue cursor-pointer" />
                            <IoNotificationsOutline className="w-7 h-7 text-h2v-blue cursor-pointer" />
                            <BsInfoCircle className="w-6 h-6 text-h2v-blue cursor-pointer" />
                        </div>
                    </div>
                </div>
                <div className="flex flex-col bg-white mt-4 mx-4 p-4 rounded-xl items-start justify-between">
                    <p className="font-poppins text-base font-semibold">{t("photocatalyticPanel")}</p>
                </div>
                <div className="flex flex-row bg-white mt-4 mx-4 p-4 rounded-xl justify-end space-x-4 items-center">
                    <p>{t("viewPeriod")}</p>
                    <Select
                        value={fromDay}
                        options={Array.from({ length: 31 }, (_, index) => {
                            const number = (index + 1).toString().padStart(2, '0')
                            return {
                                value: number,
                                label: number
                            }
                        })}
                        onChange={(e) => setFromDay(e.target.value)}
                        className="appearance-none cursor-pointer w-16 h-9 bg-white border font-poppins font-bold border-h2v-blue text-h2v-blue py-2 px-4 rounded leading-tight focus:outline-none"
                        iconColor="text-h2v-blue"
                    />
                    <Select
                        value={fromMonth}
                        options={months}
                        onChange={(e) => setFromMonth(e.target.value)}
                        className="appearance-none cursor-pointer h-9 bg-white border font-poppins font-bold border-h2v-blue text-h2v-blue py-2 pl-4 pr-6 rounded leading-tight focus:outline-none"
                        iconColor="text-h2v-blue"
                    />
                    <Select
                        value={fromYear}
                        options={Array.from({ length: 2024 - 1900 + 1 }, (_, index) => {
                            const number = (1900 + index).toString()
                            return {
                                value: number,
                                label: number
                            }
                        })}
                        onChange={(e) => setFromYear(e.target.value)}
                        className="appearance-none cursor-pointer h-9 bg-white border font-poppins font-bold border-h2v-blue text-h2v-blue py-2 pl-4 pr-6 rounded leading-tight focus:outline-none"
                        iconColor="text-h2v-blue"
                    />
                    <p>{t("until")}</p>
                    <Select
                        value={toDay}
                        options={Array.from({ length: 31 }, (_, index) => {
                            const number = (index + 1).toString().padStart(2, '0')
                            return {
                                value: number,
                                label: number
                            }
                        })}
                        onChange={(e) => setToDay(e.target.value)}
                        className="appearance-none cursor-pointer w-16 h-9 bg-white border font-poppins font-bold border-h2v-blue text-h2v-blue py-2 px-4 rounded leading-tight focus:outline-none"
                        iconColor="text-h2v-blue"
                    />
                    <Select
                        value={toMonth}
                        options={months}
                        onChange={(e) => setToMonth(e.target.value)}
                        className="appearance-none cursor-pointer h-9 bg-white border font-poppins font-bold border-h2v-blue text-h2v-blue py-2 pl-4 pr-6 rounded leading-tight focus:outline-none"
                        iconColor="text-h2v-blue"
                    />
                    <Select
                        value={toYear}
                        options={Array.from({ length: 2024 - 1900 + 1 }, (_, index) => {
                            const number = (1900 + index).toString()
                            return {
                                value: number,
                                label: number
                            }
                        })}
                        onChange={(e) => setToYear(e.target.value)}
                        className="appearance-none cursor-pointer h-9 bg-white border font-poppins font-bold border-h2v-blue text-h2v-blue py-2 pl-4 pr-6 rounded leading-tight focus:outline-none"
                        iconColor="text-h2v-blue"
                    />
                </div>
                <div className="grid m-4 gap-6 grid-cols-2">
                    <div className="w-full h-full flex flex-col bg-white p-4 rounded-xl">
                        <p className="font-poppins text-base font-semibold">{t("productionVolume")}</p>
                        <div className="w-full h-full mt-4">
                            <LineChart data={[20, 22, 23, 40, 43, 55, 60, 100, 20]} />
                        </div>
                    </div>
                    <div className="w-full h-full flex flex-col bg-white p-4 rounded-xl">
                        <div className="flex flex-row justify-between mb-2">
                            <p className="font-poppins text-base font-semibold">{t("lastCommands")}</p>
                            <p className="font-poppins font-semibold text-h2v-green cursor-pointer">{t("seeAll")}</p>
                        </div>
                        <div className="overflow-x-auto overflow-y-auto max-h-full">
                            <table className="min-w-full table-fixed">
                                <tbody className="divide-y-2">
                                    {commands.map((item, index) => (
                                        <tr key={index} className="flex justify-between py-6 px-4 items-center">
                                            <td>{item.command}</td>
                                            <td>
                                                <div className="flex items-center space-x-4">
                                                    <p>{item.from}%</p>
                                                    <FaArrowRightLong />
                                                    <p>{item.to}%</p>
                                                </div>
                                            </td>
                                            <td className="flex items-center">
                                                <div className={`w-2 h-2 rounded-full ${item.status === t("waiting") ? "bg-yellow-500" : ""} mr-2`}></div>
                                                {item.status}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="w-full h-full flex flex-col bg-white p-4 rounded-xl overflow-x-auto">
                        <p className="font-poppins text-base font-semibold">{t("reactor")}</p>
                        <div className="flex flex-row space-x-4 mt-4">
                            <div className="w-1/2 flex flex-col border-2 border-h2v-blue rounded-lg p-2">
                                <div className="flex flex-row justify-between items-center">
                                    <p className="font-poppins font-light text-sm">{t("gasFlow")}</p>
                                    <p className="font-poppins font-semibold text-lg text-h2v-green">3500ppm</p>
                                </div>
                                <LinePointedChart data={[2000, 3000, 4000, 2500, 1500, 1700, 500, 3500]} min={0} max={4000} stepSize={1000} color={'rgba(87, 151, 15, 1)'} fillColor={'rgba(87, 151, 15, 0.2)'} />
                            </div>
                            <div className="w-1/2 flex flex-col border-2 border-h2v-blue rounded-lg p-2">
                                <div className="flex flex-row justify-between items-center">
                                    <p className="font-poppins font-light text-sm">{t("temperature")}</p>
                                    <p className="font-poppins font-semibold text-lg text-h2v-green">40º</p>
                                </div>
                                <LinePointedChart data={[0, 4, 8, 9, 10, 14, 12, 0]} min={0} max={14} stepSize={4} color={'rgba(87, 151, 15, 1)'} fillColor={'rgba(87, 151, 15, 0.2)'} />
                            </div>
                        </div>
                    </div>
                    <div className="w-full h-full flex flex-col bg-white p-4 rounded-xl overflow-x-auto">
                        <p className="font-poppins text-base font-semibold">{t("glycerolTank")}</p>
                        <div className="flex flex-row space-x-4 mt-4">
                            <div className="w-1/2 flex flex-col border-2 border-h2v-blue rounded-lg p-2">
                                <div className="flex flex-row justify-between">
                                    <p className="font-poppins font-light text-sm">Ph</p>
                                    <p className="font-poppins font-semibold text-lg text-h2v-orange">14</p>
                                </div>
                                <LinePointedChart data={[0, 4, 8, 9, 10, 14, 12, 0]} min={0} max={14} stepSize={4} color={"rgba(241, 90, 37, 1)"} fillColor={"rgba(241, 90, 37, 0.2)"} />
                            </div>
                            <div className="w-1/2 flex flex-col border-2 border-h2v-blue rounded-lg p-2">
                                <div className="flex flex-row justify-between">
                                    <p className="font-poppins font-light text-sm">{t("temperature")}</p>
                                    <p className="font-poppins font-semibold text-lg text-h2v-orange">40º</p>
                                </div>
                                <LinePointedChart data={[0, 4, 8, 9, 10, 14, 12, 0]} min={0} max={14} stepSize={4} color={"rgba(241, 90, 37, 1)"} fillColor={"rgba(241, 90, 37, 0.2)"} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
