import { createAsyncThunk, createAction } from "@reduxjs/toolkit"
import { changePassword, forgotPassword, login, resetPassword, validateCode } from "../../api/auth"

export const userLogin = createAsyncThunk(
    'auth/signin',
    async ({ email, password }, { rejectWithValue }) => {
        try {
            const response = await login(email, password)
            const { accessToken, refreshToken } = response.data

            sessionStorage.setItem("accessToken", accessToken)
            sessionStorage.setItem("refreshToken", refreshToken)
            const id = JSON.parse(atob(accessToken.split('.')[1])).sub
            sessionStorage.setItem("id", id)

            return {data: response.data, id}
        } catch (error) {
            if (error.response) {
                const { status, data } = error.response
                return rejectWithValue({ status, data })
            } else {
                return rejectWithValue(error.message || 'Network error')
            }
        }
    }
)

export const userForgotPassword = createAsyncThunk(
    'auth/forgot-password',
    async ({ email }, { rejectWithValue }) => {
        try {
            const response = await forgotPassword(email)

            return {email: email, response: response.data}
        } catch (error) {
            if (error.response) {
                const { status, data } = error.response
                return rejectWithValue({ status, data })
            } else {
                return rejectWithValue(error.message || 'Network error')
            }
        }
    }
)

export const userValidateCode = createAsyncThunk(
    'auth/validate-code',
    async ({ confirmationCode }, { rejectWithValue }) => {
        try {
            const response = await validateCode(confirmationCode)
            sessionStorage.setItem("accessToken", response.data.token)

            return response.data
        } catch (error) {
            if (error.response) {
                const { status, data } = error.response
                return rejectWithValue({ status, data })
            } else {
                return rejectWithValue(error.message || 'Network error')
            }
        }
    }
)

export const userResetPassword = createAsyncThunk(
    'auth/reset-password',
    async ({ newPassword, newPasswordConfirmation }, { rejectWithValue }) => {
        try {
            const response = await resetPassword(sessionStorage.getItem("accessToken"), newPassword, newPasswordConfirmation)

            return response.data
        } catch (error) {
            if (error.response) {
                const { status, data } = error.response
                return rejectWithValue({ status, data })
            } else {
                return rejectWithValue(error.message || 'Network error')
            }
        }
    }
)


export const userChangePassword = createAsyncThunk(
    'auth/change-password',
    async ({ oldPassword, newPassword, newPasswordConfirmation }, { rejectWithValue }) => {
        try {
            const response = await changePassword(oldPassword, newPassword, newPasswordConfirmation)

            return response.data
        } catch (error) {
            if (error.response) {
                const { status, data } = error.response
                return rejectWithValue({ status, data })
            } else {
                return rejectWithValue(error.message || 'Network error')
            }
        }
    }
)

export const userLogout = createAction('auth/logout')